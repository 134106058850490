h3{
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}
p{
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
}

.icon{
    color: #FFF;
    height: 5rem;
    width: 5rem;
    font-size: 1.75rem;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.gradientDescription{
    background: rgb(255,184,48);
    background: radial-gradient(circle, rgba(255,184,48,1) 51%, rgba(255,36,66,1) 100%);
}

.descriptionMinWidth{
    min-width: 200px;

}
.gradient{
    background: rgb(255,184,48);
    background: linear-gradient(175deg, rgba(255,184,48,1) 59%, rgba(255,36,66,1) 100%);
}

.title{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 3.575em;
    font-weight: 900;
}

.slogan{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 1.675em;
    font-weight: 600;
}

.inputWidth{
    text-align: center;
    
    min-width: 200px;
    min-height: 70px;
    max-height:400px;
    max-height: 70px;
    font-size:17pt;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-width: 0;
}

.buttonWidth{
    width: 100px;
    font-size: 25px;
    height: 70px;
    background-color: #3DB2FF;
    color: #FFFFFF;
    border-width: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.bootButton{
    background-color: #3DB2FF;
    font-family: 'Nunito', sans-serif;
    font-size: 17px;
    font-weight: 600;

}

.alpha-launched{
    
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 2.2em;
    font-weight: 600;
    margin-top: 30px;
}

.blueButtonCirc{

    
        color: #FFFFFF;
        font-family: 'Nunito', sans-serif;
        font-size: 1.2em;
        font-weight: 900;

}
.bg-navBgColor{
    background-color: #FFB830;
    
}

.textColor{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-weight: 900;

   
}

.logo{
    color: #3DB2FF;
    font-family: 'Nunito', sans-serif;
    font-size: 1.675em;
    font-weight: 900;

}


a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }
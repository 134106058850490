.withOutTopGradient{
    background: rgb(255,184,48);
    background: linear-gradient(355deg, rgba(255,184,48,1) 35%, rgba(255,36,66,1) 91%);
}

.withOutCardTitle{
    
    font-family: 'Nunito', sans-serif;
    font-size: 2em;
    font-weight: 600;
}

.cardProblem{
    min-width: 200px;
}

.withOutCardText{
    min-height: 100px;
}
.problemDescription{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 1.675em;
    font-weight: 300;
}

.problemEmphasis{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 1em;
    font-weight: 600;
}

.whyProblemEmphasis{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 1.6em;
    font-weight: 600;
}


.problemTitle{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 3.575em;
    font-weight: 900;
}
.solutionTitle{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 3.575em;
    font-weight: 900;
}

.solutionSubtitle{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 2.575em;
    font-weight: 600;
}

.solutionFeatureHeading{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 2.175em;
    font-weight: 600;
}

.solutionFeatureSubHeading{
    color: #FFFFFF;
    font-family: 'Nunito', sans-serif;
    font-size: 1.175em;
    font-weight: 300;
}

.cardDimentions{
    max-width: 300px;
    max-height: 300px;
    border-width: 0px;
    
}